<template>
    <AppLayout>
        <Tutorial>
            <p class="text-subtitle-1 font-weight-light text-center">To protect your account with LoginShield, follow the steps on this page.</p>
        </Tutorial>
       <v-row justify="center" class="py-5">
            <h1 class="text-h6 font-weight-light">Protect your account</h1>
        </v-row>
        <v-row justify="center" v-if="interactionId && interactionType === 'link_realm_user'">
            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="pa-0">
                <v-row justify="center">
                    <v-card elevation="6" class="px-4 pb-2">
                        <v-list-item>
                            <v-col>
                                <v-row>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6 font-weight-light">
                                            {{realmLabel}}
                                        </v-list-item-title>
                                        <v-spacer></v-spacer>
                                        <v-list-item-subtitle>
                                            {{realmDomain}}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            {{realmOwnerName}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="end">
                                    <v-img contain :src='realmImageSrc' max-width="48" max-height="48" v-if="realmImage"/>
                                </v-row>
                            </v-col>
                        </v-list-item>
                        <v-divider class="mx-5 mb-2"></v-divider>
                        <div v-if="isAuthenticated">
                            <v-row class="py-5" justify="center">
                                <v-btn @click="reject" elevation="6" class="grey white--text ml-10" min-width="120">Cancel</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn @click="accept" elevation="6" class="blue white--text mr-10" min-width="120">Continue</v-btn>
                            </v-row>
                        </div>
                        <div v-if="!isAuthenticated">
                            <v-row class="pt-6 text-body-1 font-weight-light" justify="center">
                                <p>Returning users, login to continue:</p>
                            </v-row>
                            <v-row class="pb-6" justify="center">
                                <v-btn @click="login" elevation="6" class="blue white--text">Login</v-btn>
                            </v-row>
                            <v-row class="pt-3 px-3 text-body-1 font-weight-light text-center" justify="center">
                                <p>Is this your first time at LoginShield?<br/>You only need an email address to get started:</p>
                            </v-row>
                            <v-row class="pb-3" justify="center">
                                <v-btn href="/software/" elevation="6" class="blue white--text">Get the app</v-btn>
                            </v-row>
                        </div>
                    </v-card>
                 </v-row>
                 <v-row v-if="error" justify="center">
                     <v-col cols="12">
                         <p>An error occurred while processing the request.</p>
                     </v-col>
                 </v-row>
            </v-col>
        </v-row>
        <v-row v-if="interactionId && interactionType !== 'link_realm_user'" justify="center">
            <v-col cols="12">
                <p>An error occurred while processing the request.</p>
            </v-col>
        </v-row>
    </AppLayout>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import Tutorial from '@/components/Tutorial.vue';
import AppLayout from '@/components/AppLayout.vue';
import loginshield from '@/client';

export default {
    components: {
        Tutorial,
        AppLayout,
    },
    data() {
        return {
            interactionId: null,
            interactionType: null,
            realmLabel: null,
            realmDomain: null,
            realmImage: null,
            realmOwnerName: null,
            error: false,
        };
    },
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
            interactionMap: (state) => state.interactionMap,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        realmImageSrc() {
            return `data:image/png;base64,${this.realmImage}`;
        },
        // ...mapGetters({
        // }),
    },
    watch: {
        isReady(value, oldValue) {
            console.log('register.vue: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
    },
    methods: {
        async init() {
            try {
                this.interactionId = this.$route.query.i;
                // check if we already have the interaction info cached
                let response = this.interactionMap[this.interactionId];
                if (!response) {
                    response = await this.$store.dispatch('loadInteraction', this.interactionId);
                }
                console.log('register.vue: interaction: %s', JSON.stringify(response));
                const { type, state } = response;
                if (type === 'link_realm_user') {
                    this.realmLabel = state.realmLabel;
                    this.realmDomain = state.realmDomain;
                    this.realmImage = state.realmImage;
                    this.realmOwnerName = state.realmOwnerName;
                }
                this.interactionType = type;
            } catch (err) {
                console.error('register.vue: init failed: %o', err);
                this.error = true;
            }
        },
        login() {
            // redirect user to login; the end of the login process will check if
            // an enterprise user registration is in progress and redirect the user here again
            console.log(`register.vue: login with interaction id: ${this.interactionId}`);
            this.$router.push({ path: '/login', query: { i: this.interactionId } });
        },
        async accept() {
            this.error = false;
            // link the user to the enterprise realm; the interaction is finished
            try {
                const response = await loginshield.interaction.edit(this.interactionId, { action: 'accept' });
                console.log('register.vue: accept link realm user response: %o', response);
                if (response.forward) {
                    // redirect user to enterprise site
                    window.location = response.forward;
                } else {
                    console.log('register.vue: error: unexpected response from server');
                    this.error = true;
                }
            } catch (err) {
                console.error('register.vue: failed to accept registration: %o', err);
                this.error = true;
            }
        },
        async reject() {
            // do not link the user to the enterprise realm; the interaction is cancelled
            try {
                const response = await loginshield.interaction.edit(this.interactionId, { action: 'reject' });
                console.log('register.vue: reject link realm user response: %o', response);
                // await this.$store.dispatch('loadSession'); // to clear the pendingLinkRealmUser flag from the session
                this.$router.push('/login-redirect');
            } catch (err) {
                console.error('register.vue: failed to reject registration: %o', err);
                this.$router.push('/login-redirect');
            }
        },
        /*
        async fetchActionData() {
            const response = await loginshield.session.getActionData();
            this.realmLabel = response.realmLabel;
            this.realmDomain = response.realmDomain;
            this.realmImage = response.realmImage;
            this.realmOwnerName = response.realmOwnerName;
        },
        */
    },
    mounted() {
        console.log('mounted');
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
