<template>
    <div v-if="isTutor">
        <v-row justify="center">
            <v-col cols="12" sm="10" md="8" lg="8" xl="8" class="pa-0">
                <v-row justify="center" class="px-3">
                    <slot></slot>
                </v-row>
                <v-row justify="center" class="py-5">
                    <v-btn fab outlined color="red" x-small @click="isTutor = false">
                        <font-awesome-icon icon="times" fixed-width size="1x"/>
                    </v-btn>
                    <v-btn elevation="6" outlined color="blue" class="ml-10" :to="next" v-if="next">
                        Continue
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>
        <v-divider></v-divider>
    </div>
</template>

<script>
export default {
    props: {
        next: {
            type: String,
        },
    },
    computed: {
        isTutor: {
            get() {
                return this.$store.state.isTutorEnabled;
            },
            set(value) {
                this.$store.dispatch('setTutorState', value);
            },
        },
    },
};
</script>
